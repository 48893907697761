import React from "react"
import 'bootstrap/dist/css/bootstrap.min.css'
import Header from '../components/HeaderWhite/header'
import Footer from '../components/Footer/footer'
import DownloadPage from '../components/Download/index'
import { Helmet } from "react-helmet";

import logo from '../components/img/downloads.png'

// const content = `Download DueFocus to track time on Windows, MacOS or Linux with our powerful feature set`;
const content = `Duefocus is a free time tracking software that enables tracking time with timesheets and sends advanced reports`

const Download = () => (
  <>
    <Helmet>
      <html lang="en" />
      <meta charSet="utf-8" />
      {/* <title>Track Time on Windows, MacOS, Linux with DueFocus</title> */}
      <title>Duefocus is a free time tracker for remote teams</title>
      <meta name="description" content={content} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content="Duefocus is a free time tracker for remote teams" />
      <meta property="og:description" content={content} />
      <meta property="og:url" content="https://www.duefocus.com/" />
      <meta property="og:image" content={logo} />
      <meta name="twitter:image:src" content={logo} />
      <meta name="robots" content="index, follow" />
      <link rel="canonical" href="https://www.duefocus.com/download/" />
    </Helmet>
    <Header />
    <DownloadPage />
    <Footer />
  </>
)

export default Download