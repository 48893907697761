import React from 'react'
import axios from 'axios'
import styles from './index.module.scss'
import button from '../buttons.module.scss'
// import logo from '../img/Logo_download.svg'
import logo from '../img/Logo_icon-new-1.svg'
import EmptyBlock from '../Header/emptyBlock'

const Download = () => {

  //   const handleCloseFeatures = () => {
  //     // document.getElementById('drop-down').classList.remove('drop-down-active');
  //     document.getElementById('overlay').removeAttribute('class');
  //     document.getElementById('drop-down-pricing').classList.remove('drop-down-active');
  //     document.body.removeAttribute('class');
  // }
  // const getChromeExtension = () => {
  //    return window.location.href = 'https://chrome.google.com/webstore/detail/duefocus-time-tracking-an/hikfcbemfcpjogibnoniebgbhoeebeog?hl';
  // }

  const goToThankYouPage = () => {
    setTimeout(function () {
      window.location.href = 'https://duefocus.com/Thank-you/';
    }, 1000);
  }

  // const getDownloadLink = async () => {
  //   const API_URL = 'https://web.duefocus.com'
  //   let url;
  //   if ((navigator.appVersion.indexOf('Linux') !== -1) || (navigator.appVersion.indexOf('X11') !== -1)) {
  //     url = `${API_URL}/distribution/linux/v3/latest.json`;
  //     await axios(url).then(response => {
  //       window.location.href = `${API_URL}/distribution/linux/v3/${response.data.fileName}`;
  //       goToThankYouPage();
  //     });
  //   } else if (navigator.appVersion.indexOf('Win') !== -1) {
  //     url = `${API_URL}/distribution/win32/v3/latest.json`;
  //     await axios(url).then(response => {
  //       window.location.href = `${API_URL}/distribution/win32/v3/${response.data.fileName}`;
  //       goToThankYouPage();
  //     });
  //   } else if (navigator.appVersion.indexOf('Mac') !== -1) {
  //     url = `${API_URL}/distribution/darwin/v3/latest.json`;
  //     await axios(url).then(response => {
  //       window.location.href = `${API_URL}/distribution/darwin/v3/DueFocus-${response.data.version}.dmg`;
  //       goToThankYouPage();
  //     });
  //   }
  // };
  const openChromeApp = () => {
    return window.open(
      'https://chrome.google.com/webstore/detail/duefocus-time-tracking-ap/jmjoillacjphggegfecfofhagpcfnhda',
      '_blank'
    );
  }
  return (
    <>
      <EmptyBlock />
      {/* <div onMouseEnter={this.handleCloseFeatures} id='overlay'></div>< */}
      <div className={styles.main}>
        <div>
          <img src={logo} alt='logo' width={120} height={120} />
          <br />
          <br />
          <div className={styles.text}>
            {/* <h1>Download DueFocus for Desktop</h1>
         <p>Enjoy DueFocus Desktop Application in all OS</p> */}
            <h1>Download DueFocus now</h1>
            <p>Enjoy time tracking in Google Chrome</p>
          </div>
          <div className={styles.download}>
            <button
              // onClick={getDownloadLink}
              onClick={openChromeApp}
              className={button.buttonSignUp}
              id='download'
            >
              Download App
            </button>
          </div>
        </div>
      </div>
    </>
  )
}


export default React.memo(Download);